<script>
    import { getEmoji } from "./emojiMap";

    export let zone;
</script>

<div class="zone-stats">
    <div>
        {#each zone.keywords as keyword}
            <span title={keyword}>{getEmoji(keyword)}</span>&nbsp;
        {/each}
    </div>
    <div>
        {#each zone.stats as stat}
            <span title={stat.type}>{stat.value}{getEmoji(stat.type)}</span
            >&nbsp;
        {/each}
    </div>
</div>

<style>
    .zone-stats {
        text-align: center;
        font-size: large;
        background-color: rgb(16, 16, 16);
    }
    .zone-stats div {
        min-height: 1.5em;
    }
</style>
