<!-- RenderableContent.svelte to display emojis {marked} on cards -->
<script>
    import Emoji from "./Emoji.svelte";
    import { getEmoji } from "./emojiMap.js";

    export let content = "";

    function parseContent(content) {
        const regex = /\{(\d+[^a-zA-Z]*)?([a-zA-Z]+)([^a-zA-Z]*\d+)?\}/g;
        const parts = [];
        let lastIndex = 0;
        let match;

        while ((match = regex.exec(content)) !== null) {
            if (match.index > lastIndex) {
                parts.push({
                    type: "text",
                    content: content.slice(lastIndex, match.index),
                });
            }
            parts.push({
                type: "emoji",
                keyword: match[2],
                prefix: match[1] || "",
                suffix: match[3] || "",
            });
            lastIndex = match.index + match[0].length;
        }

        if (lastIndex < content.length) {
            parts.push({ type: "text", content: content.slice(lastIndex) });
        }

        return parts;
    }
</script>

{#each parseContent(content) as part}
    {#if part.type === "text"}
        <span class="content">{part.content}</span>
    {:else}
        <Emoji
            emoji={getEmoji(part.keyword)}
            prefix={part.prefix}
            suffix={part.suffix}
            name=""
            definition=""
        />
    {/if}
{/each}

<style>
    span {
        background-color: rgb(0, 0, 0, 0.66);
    }
</style>
