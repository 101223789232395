<script>
    import { selectedCard } from "./stores.js";
    import RenderableContent from "./RenderableContent.svelte";
</script>

<div class="header">
    {#if $selectedCard}
        <span class="factions"
            ><RenderableContent content={$selectedCard.factions} /></span
        >
        <span class="name"
            ><RenderableContent content={$selectedCard.name} /></span
        >
        <span class="cost"
            ><RenderableContent content={$selectedCard.cost} /></span
        >{$selectedCard.cost > 0 ? "💠" : ""}
        <span class="keywords"
            ><RenderableContent content={$selectedCard.keywords} /></span
        >
        {#if $selectedCard.text_1_type}
            <span class="text_1"
                ><RenderableContent
                    content={$selectedCard.text_1_type}
                />:<RenderableContent content={$selectedCard.text_1} /></span
            >
        {/if}
        {#if $selectedCard.text_2_type}
            <span class="text_2"
                ><RenderableContent
                    content={$selectedCard.text_2_type}
                />:<RenderableContent content={$selectedCard.text_2} /></span
            >
        {/if}
        {#if $selectedCard.text_3_type}
            <span class="text_3"
                ><RenderableContent
                    content={$selectedCard.text_3_type}
                />:<RenderableContent content={$selectedCard.text_3} /></span
            >
        {/if}
        <span class="tags"
            ><RenderableContent content={$selectedCard.tags} /></span
        >
        <span class="attack"
            ><RenderableContent content={$selectedCard.attack} /></span
        >{$selectedCard.attack ? "⚔️" : ""}
        <span class="type"
            ><RenderableContent content={$selectedCard.type} /></span
        >
        <span class="health"
            ><RenderableContent content={$selectedCard.health} /></span
        >{$selectedCard.health ? "❤️" : ""}
    {:else}
        (Tap a card to select it.)
    {/if}
</div>

<style>
    .header {
        background-color: black;
        color: white;
        text-align: center;
    }
    .type {
        color: gray;
    }
</style>
