<script>
    import Card from "./Card.svelte";

    export let cardData = [];
</script>

<div class="library">
    <div class="controls">Search & Filters</div>
    <div class="saved_decks">Selected Deck (tap for decks)</div>
    <div class="cards">
        {#each cardData as card}
            <Card {card} />
        {/each}
    </div>
    <div class="deck">
        <div class="deck_cards">Cards in deck...</div>
        <div class="deck_stats">Deck Stats [curve etc]</div>
    </div>
</div>

<style>
    .library {
        display: grid;
        grid-template-columns: 3fr 1fr; /* First column 3/4, second column 1/4 */
        grid-template-rows: auto 1fr; /* First row auto, second row takes remaining space */
        height: 95vh;
        gap: 0px;
    }
    .controls,
    .saved_decks,
    .cards,
    .deck {
        border: 1px solid black;
        padding: 5px;
        background-color: #f9f9f9;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0;
        padding: 0;
    }
    .deck_cards {
        flex: 1;
        overflow-y: auto;
    }
    .deck_stats {
        background-color: cyan;
        flex-shrink: 0;
        border: 1px solid black;
    }
    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* Three columns */
        gap: 5px; /* Adjust the gap between the cards as needed */
        overflow-y: auto; /* Scroll if the content overflows vertically */
        background-color: black;
    }
</style>
