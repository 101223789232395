<script>
    export let name = "";
    export let prefix = "";
    export let emoji = "⚠️";
    export let suffix = "";
    export let definition = "";
</script>

<span class="emoji">
    {prefix}
    {emoji}
    {suffix}
    {name}
    {definition}
</span>

<style>
    .emoji {
        background-color: rgb(0, 0, 0, 0.66);
    }
</style>
