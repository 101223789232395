<script>
    import ZoneStats from "./ZoneStats.svelte";
    import CardList from "./CardList.svelte";

    export let zones = [];
</script>

<div class="zones">
    {#each zones as zone}
        <div class="zone" role="list">
            <ZoneStats {zone}></ZoneStats>
            <CardList cards={zone.cards}></CardList>
        </div>
    {/each}
</div>

<style>
    .zones {
        display: flex;
    }
    .zone {
        color: white;
        flex: 1 1 0px;
        height: 94vh;
        background-color: #0d0042;
        border: 1px solid black;
        box-sizing: border-box;
        overflow: hidden;
    }
</style>
