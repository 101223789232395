<script>
    export let card;
    import { selectedCard } from "./stores.js";
    import RenderableContent from "./RenderableContent.svelte";
    let selected = false;

    // Subscribe to selectedCard to reactively update the card's selected state
    $: selected = $selectedCard && $selectedCard.id === card.id;

    function handleClick() {
        selectedCard.set(card);
    }
</script>

<button class="card" on:click={handleClick}>
    <div class="frame {selected ? 'selected' : ''}">
        <div class="factions">
            <RenderableContent content={card.factions} />
        </div>

        <div class="name">
            <RenderableContent content={card.name} />
        </div>

        <div class="cost">
            <RenderableContent content={card.cost} />
            {card.cost > 0 ? "💠" : ""}
        </div>

        <div class="keywords">
            <RenderableContent content={card.keywords} />
        </div>

        <div class="text">
            <div class="text_1">
                <RenderableContent content={card.text_1} />
            </div>
            <div class="text_2">
                <RenderableContent content={card.text_2} />
            </div>
            <div class="text_3">
                <RenderableContent content={card.text_3} />
            </div>
        </div>

        <div class="tags">
            <RenderableContent content={card.tags} />
        </div>

        <div class="attack">
            <RenderableContent content={card.attack} />
            {card.attack ? "⚔️" : ""}
        </div>

        <div class="type">
            <RenderableContent content={card.type} />
        </div>

        <div class="health">
            <RenderableContent content={card.health} />
            {card.health ? "❤️" : ""}
        </div>
    </div>
</button>

<style>
    .card {
        width: 100%;
        padding: 0;
        margin: 0;
        border: 0;
        cursor: pointer;
        aspect-ratio: 1 / 1; /* Ensure the card is square */
    }
    .frame {
        display: grid;
        grid-template-columns: auto 1fr auto; /* Three equal columns */
        grid-template-rows: auto 1fr auto; /* Three rows */
        border: 1px solid gray;
        padding: 1px;
        gap: 0px;
        color: white;
        text-shadow: 0px 0px 10px black; /* Optional: Adds a black shadow to enhance visibility */
        background-color: #fff; /* Optional: Add a background color */
        font-size: calc(1vw + 1vh + 0.5vmin);
        aspect-ratio: 1 / 1; /* Ensure the card is square */
        background-image: url("/card_images/cube.png"); /* Set background image */
        background-size: cover; /* Cover the entire area of the card */
        background-position: center; /* Center the background image */
    }
    .selected {
        border-color: yellow;
    }

    .factions,
    .name,
    .cost,
    .keywords,
    .text,
    .tags,
    .attack,
    .type,
    .health {
        padding: 0px; /* Optional: Add some padding inside each cell */
    }

    /* middle row */
    .keywords,
    .text,
    .tags {
        display: flex; /* Use flexbox for vertical alignment */
        align-items: center; /* Center the text vertically */
    }

    .factions {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        text-align: left;
    }
    .name {
        grid-column: 2 / span 1;
        grid-row: 1 / span 1;
        text-align: center;
    }
    .cost {
        grid-column: 3 / span 1;
        grid-row: 1 / span 1;
        text-align: right;
    }
    .keywords {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
        text-align: left;
    }
    .text {
        font-size: calc(0.8vw + 0.8vh + 0.5vmin);
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
        justify-content: center; /* Optional: Center the text horizontally */
        text-align: center;
        display: flex; /* Enable flexbox for vertical stacking */
        flex-direction: column; /* Stack children vertically */
        align-items: center; /* Center align children horizontally */
    }
    .tags {
        grid-column: 3 / span 1;
        grid-row: 2 / span 1;
        text-align: right;
    }
    .attack {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
        text-align: left;
    }
    .type {
        grid-column: 2 / span 1;
        grid-row: 3 / span 1;
        text-align: center;
        color: gray;
        font-size: calc(0.7vw + 0.7vh + 0.5vmin);
    }
    .health {
        grid-column: 3 / span 1;
        grid-row: 3 / span 1;
        text-align: right;
    }
</style>
