<script>
  import Card from "./Card.svelte";

  export let cards;
</script>

<ul class="card-list">
  {#each cards as card}
    <li>
      <Card {card} />
    </li>
  {/each}
</ul>

<style>
  .card-list {
    padding: 0;
    margin: 0;
    list-style-type: none; /* removes list bullet */
    overflow-y: auto;
    max-height: 90vh; /* max height for scrolling */
  }
  .card-list li {
    margin-bottom: 2px;
  }
</style>
