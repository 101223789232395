<script>
  import { onMount } from "svelte";
  import Game from "./Game.svelte";
  import Library from "./Library.svelte";
  import Header from "./Header.svelte";
  import {
    fetchCardData,
    initializeCardData,
    fetchGameData,
    initializeGameData,
  } from "./dataManager";

  let cardData = [];
  let zoneData = [];

  let scene = "library";

  function toggleScene() {
    scene = scene === "game" ? "library" : "game";
  }

  onMount(async () => {
    try {
      cardData = await fetchCardData();
      cardData = initializeCardData(cardData);

      const game_data = await fetchGameData();
      zoneData = initializeGameData(cardData, game_data);
    } catch (error) {
      console.error("Failed to load game data:", error);
    }
  });
</script>

<main>
  <Header />

  {#if scene === "game"}
    <Game zones={zoneData}></Game>
  {:else}
    <Library {cardData}></Library>
  {/if}

  <div class="footer">
    <button on:click={toggleScene}
      >{scene === "game" ? "Go to Library" : "Go to Game"}</button
    >
  </div>
</main>

<style>
  .footer {
    background-color: #0d0042;
    text-align: center;
    display: flex;
    justify-content: left;
    align-items: left;
  }
  button {
    background-color: #4caf50;
    border: none;
    color: white;
    text-align: center;
    display: inline-block;
    font-size: small;
    height: 3vh;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  button:hover {
    background-color: white;
    color: black;
  }
</style>
