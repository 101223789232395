export function getEmoji(keyword) {
    if (!keyword) {
        return "";
    }
    if (emojis.find(e => e.name === keyword)) {
        return emojis.find(e => e.name === keyword).emoji;
    } else {
        console.log("Keyword Not Found:", keyword)
    }
}

export const emojis = [
    { name: 'attack', emoji: '⚔️', definition: 'Deals this much damage each turn.' },
    { name: 'dazed', emoji: '🌀', definition: '???' },
    { name: 'deck', emoji: '📚', definition: 'This player\'s deck.' },
    { name: 'die_1', emoji: '⚀', definition: '???' },
    { name: 'die_2', emoji: '⚁', definition: '???' },
    { name: 'flower', emoji: '🌼', definition: '???' },
    { name: 'grave', emoji: '🪦', definition: '???' },
    { name: 'health', emoji: '❤️', definition: 'Can take this much damage.' },
    { name: 'level_1', emoji: '1️⃣', definition: '???' },
    { name: 'level_2', emoji: '2️⃣', definition: '???' },
    { name: 'mana', emoji: '💠', definition: 'Spend mana to play cards. Refills each turn.' },
    { name: 'poison', emoji: '🤢', definition: '???' },
    { name: 'shield', emoji: '🛡️', definition: 'Take this much less damage each turn.' },
    { name: 'handy', emoji: '🎒', definition: 'Playable from deck.' },
    { name: 'rat', emoji: '🐀', definition: 'Is a rat.' },
    { name: 'fast', emoji: '⏩', definition: 'Activates before combat.' },
    { name: 'slow', emoji: '🐌', definition: 'Activates after combat next turn.' },
    { name: 'taunt', emoji: '🗣️', definition: 'An enemy must block damage each turn if able.' },
    { name: 'copy', emoji: '🧑‍🤝‍🧑', definition: 'Summons copy when played.' },
    { name: 'damage', emoji: '💥', definition: 'Deals damage to the enemy.' },
    { name: 'heal', emoji: '❤️‍🩹', definition: 'You heal this much damage.' },
    { name: 'place', emoji: '🏝️', definition: 'Play one place each turn.' },
    { name: 'passive', emoji: '🟢', definition: 'On while in play.' },
    { name: 'trait', emoji: '✅', definition: 'On at all times.' },
    { name: 'stall', emoji: '⏬', definition: 'Stall this for a turn to activate.' },
    { name: 'trigger', emoji: '🔹', definition: 'Triggers based on a condition.' },
    { name: 'sunset', emoji: '🌓', definition: 'End of turn (before combat).' },
    { name: 'sunrise', emoji: '☀️', definition: 'Start of turn (after combat).' },
    { name: 'death', emoji: '💀', definition: 'When this dies.' },
    { name: 'enrage', emoji: '😡', definition: 'When this takes damage.' },
    { name: 'A', emoji: '👽', definition: 'Alien faction' },
    { name: 'B', emoji: '🔪', definition: 'Blade faction' },
    { name: 'C', emoji: '⭐', definition: 'Celestial faction' },
    { name: 'D', emoji: '👁️', definition: 'Dream faction' },
    { name: 'E', emoji: '🎵', definition: 'Entertainment faction' },
    { name: 'F', emoji: '🔥', definition: 'Fire faction' },
    { name: 'G', emoji: '💰', definition: 'Greed faction' },
    { name: 'H', emoji: '🖐️', definition: 'Humanity faction' },
    { name: 'I', emoji: '❄️', definition: 'Ice faction' },
    { name: 'J', emoji: '🃏', definition: 'Joker faction' },
    { name: 'K', emoji: '👹', definition: 'Kaiju faction' },
    { name: 'L', emoji: '📚', definition: 'Lore faction' },
    { name: 'M', emoji: '🌊', definition: 'Marine faction' },
    { name: 'N', emoji: '🌳', definition: 'Nature faction' },
    { name: 'O', emoji: '👼', definition: 'Overworld faction' },
    { name: 'P', emoji: '☮️', definition: 'Peace faction' },
    { name: 'Q', emoji: '🏆', definition: 'Quest faction' },
    { name: 'R', emoji: '🧠', definition: 'Reason faction' },
    { name: 'S', emoji: '🗝️', definition: 'Secrecy faction' },
    { name: 'T', emoji: '⚙️', definition: 'Tech faction' },
    { name: 'U', emoji: '😈', definition: 'Underworld faction' },
    { name: 'V', emoji: '☣️', definition: 'Venom faction' },
    { name: 'W', emoji: '🐾', definition: 'Wild faction' },
    { name: 'X', emoji: '❌', definition: 'X faction' },
    { name: 'Y', emoji: '🥐', definition: 'Yum faction' },
    { name: 'Z', emoji: '⬛', definition: 'Zero faction' }
];