import { parse } from "papaparse";

/* CARD DATA FOR LIBRARY */
export async function fetchCardData() {
    const response = await fetch("cards.csv");
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const csvData = await response.text();
    return new Promise((resolve, reject) => {
        parse(csvData, {
            header: true,
            complete: (results) => resolve(results.data),
            error: (err) => reject(err.message),
        });
    });
}

export function initializeCardData(cardData) {
    return cardData.map((card) => {
        const processedCard = {};
        for (const key in card) {
            processedCard[key] = card[key] !== undefined ? card[key] : ""; // undefined is ""
        }
        return processedCard;
    });
}

/* GAME DATA FOR GAME */
export async function fetchGameData() {
    const response = await fetch("game.json");
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
}

export function initializeGameData(card_data, game_data) {
    // transform zones (???)
    return game_data.zones.map((zone) => ({
        ...zone,
        cards: zone.cards.map((cardId) => getCardDetails(card_data, cardId)),
    }));
}

function getCardDetails(card_data, cardId) {
    const card = card_data.find((card) => card.id === cardId);
    return card || { id: "not_found", content: "Card details not found" };
}